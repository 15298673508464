import React, { createContext, useEffect, useState, useContext } from 'react';
import { useProjectContext } from './project.context';
import {
  getProjectMessages,
  sendMessageToProject,
  streamResponse,
} from '../utils/api/projects';
import { getCreatorById } from '../utils/api/creators';
import { CreatorsContext } from './creator.context';

export const ProjectMessagesContext = createContext({
  projectMessages: [],
  projectCreators: [],
  addMessageToCurrentProject: async (message, options, creatorId) => {},
  addProjectCreator: (creator) => {},
  isLoading: false,
});

export const ProjectMessagesProvider = ({ children }) => {
  const { currentProject } = useProjectContext();
  const { setCurrentCreator } = useContext(CreatorsContext);
  const [projectMessages, setProjectMessages] = useState([]);
  const [projectCreators, setProjectCreators] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCreatorById = async (creatorId) => {
    try {
      const creator = await getCreatorById(creatorId);
      updateProjectCreators(creator);
    } catch (error) {
      console.error('Error fetching creator:', error);
    }
  };

  const addProjectCreator = (creator) => {
    updateProjectCreators(creator);
  };

  const updateProjectCreators = (creator) => {
    setProjectCreators(prevCreators => {
      // Check if creator already exists
      if (prevCreators.some(c => c.id === creator.id)) {
        return prevCreators;
      }
      return [...prevCreators, creator].sort((a, b) =>
        a.persona.handle.localeCompare(b.persona.handle)
      );
    });
  };

  useEffect(() => {
    const fetchProjectMessages = async (projectId) => {
      setIsLoading(true);
      setProjectCreators([]);
      
      const messages = await getProjectMessages(projectId);
      const uniqueCreators = new Set();
      let firstCreator = null;
      
      for (const message of messages) {
        if (message.metadata?.creator_id && !uniqueCreators.has(message.metadata.creator_id)) {
          uniqueCreators.add(message.metadata.creator_id);
          console.log(message.metadata.creator_id);
          const creator = await getCreatorById(message.metadata.creator_id);
          updateProjectCreators(creator);
          
          if (!firstCreator) {
            firstCreator = creator;
          }
        }
      }

      if (firstCreator) {
        setCurrentCreator(firstCreator);
      }

      setProjectMessages(messages);
      setIsLoading(false);
    };

    if (currentProject) {
      fetchProjectMessages(currentProject.id);
    }
  }, [currentProject, setCurrentCreator]);

  const addMessageToCurrentProject = async (message, options, creatorId) => {
    try {
      
      // Send user message to API
      const createdMessage = await sendMessageToProject(
        currentProject.id,
        creatorId,
        message,
        options,
      );
      
      const newProjectMessages = [...projectMessages, createdMessage];

      // Add user message to project messages
      setProjectMessages(newProjectMessages);

      const currentTime = Date.now();

      // Stream AI response
      const response = await streamResponse(currentProject.id);
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let aiResponse = '';

      const readStream = async () => {
        try {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = decoder.decode(value, { stream: true });
            const lines = chunk.split('\n');
            for (const line of lines) {
              if (line.trim().startsWith('data: ')) {
                const content = JSON.parse(line.trim().substring(6));
                if (content.type === 'message') {
                  setProjectMessages([...newProjectMessages, content.message]);
                } else if (content.type === 'textDelta') {
                  aiResponse += content.content;

                  const updatedMessage = {
                    id: `ai-response-${currentTime}`,
                    role: 'assistant',
                    content: [{ type: 'text', text: { value: aiResponse } }],
                    created_at: Date.now(),
                  };

                  const updatedMessages = [...newProjectMessages];
                  const lastMessage =
                    updatedMessages[updatedMessages.length - 1];
                  if (lastMessage.id === updatedMessage.id) {
                    updatedMessages[updatedMessages.length - 1] =
                      updatedMessage;
                  } else {
                    updatedMessages.push(updatedMessage);
                  }
                  // Update messages array with partial AI response
                  setProjectMessages(updatedMessages);
                }
              }
            }
          }
        } catch (error) {
          console.error('Error reading stream:', error);
        }
      };

      await readStream();
    } catch (error) {
      console.error('Error in addMessageToCurrentProject:', error);
    }
  };

  return (
    <ProjectMessagesContext.Provider
      value={{
        projectMessages,
        addMessageToCurrentProject,
        projectCreators,
        addProjectCreator,
      }}
    >
      {children}
    </ProjectMessagesContext.Provider>
  );
};
