import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import CreatorTopNavigation from '../../components/creator-top-navigation/creator-top-navigation.component';
import TuneYourVoice from '../../components/tune-your-voice/tune-your-voice.component';
import TuneYourDesign from '../../components/tune-your-design/tune-your-design.component';
import TuneYourAI from '../../components/tune-your-ai/tune-your-ai.component';
import { getCreatorLink, updateCreator, getCreator, getCreatorById } from '../../utils/api/creators';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCtaContext } from '../../context/cta.context';
import { useMyCreatorContext } from '../../context/my-creator.context';
import {
  deleteCreator,
  createCreatorPersona,
  updateCreatorPersona,
} from '../../utils/api/creators';

import './tune-your-twin.styles.css';

const TuneYourTwin = () => {
  const [selectedTab, setSelectedTab] = useState('personality');
  const [creatorId, setCreatorId] = useState(null);
  const [personaPersonality, setPersonaPersonality] = useState(null);
  const [personaSettings, setPersonaSettings] = useState({
    handle: '',
    tagline: '',
    description: '',
    instructions: [],
    approaches: [],
    display_name: '',
  });
  const [displayName, setDisplayName] = useState('');
  const [designStyle, setDesignStyle] = useState({
    preferred_styles: [],
    extra_styles: [],
    signature_style: '',
    preferred_hues_finishes: [],
    extra_hues_finishes: [],
    preferred_materials: [],
    extra_materials: [],
  });
  const { activeCta, setActiveCta } = useCtaContext('ctaInactive');
  const [hoverPosition, setHoverPosition] = useState({ left: 0, width: 0 });
  const [creatorTexts, setCreatorTexts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { myCreator, setMyCreator } = useMyCreatorContext();
  const menuRef = useRef(null);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('myCreator', myCreator);
    if (!myCreator) return;

    setCreatorId(myCreator.id);
    if (myCreator.personality) {
      setPersonaPersonality(myCreator.personality);
    } else {
      setPersonaPersonality({
        engagement_style: 50,
        expression_style: 50,
        tone_style: -50,
        flow_style: -50,
      });
    }

    if (myCreator.persona) {
      setPersonaSettings(myCreator.persona);
    } else {
      setPersonaSettings({
        handle: '',
        tagline: '',
        description: '',
        instructions: [],
        approaches: [],
      });
    }

    if (myCreator.design_style) {
      setDesignStyle({
        ...myCreator.design_style,
        extra_styles: [],
        extra_hues_finishes: [],
        extra_materials: [],
      });
    } else {
      setDesignStyle({
        preferred_styles: [],
        extra_styles: [],
        signature_style: '',
        preferred_hues_finishes: [],
        extra_hues_finishes: [],
        preferred_materials: [],
        extra_materials: [],
      });
    }
  }, [myCreator]);

  useEffect(() => {
    const selectedElement = menuRef.current?.querySelector('.selected');
    if (selectedElement) {
      const { offsetLeft, offsetWidth } = selectedElement;
      setHoverPosition({ left: offsetLeft, width: offsetWidth });
    }
    containerRef.current.scrollTo({ top: 0 });
  }, [selectedTab]);

  const handleNext = (tab) => {
    handleTabChange(tab);
  };

  const handleDelete = async () => {
    try {
      await deleteCreator(creatorId);
      toast.success('Creator deleted successfully!');
      navigate('/project');
    } catch (e) {
      toast.error('Failed to delete creator. Please try again.');
    }
  };

  const handleSave = async () => {
    if (!personaPersonality || !personaSettings || !designStyle) {
      toast.error('Please complete all sections before saving.');
      return;
    }

    if (!personaSettings.handle) {
      toast.error('Handle is required');
      return;
    }

    const cleanDesignStyle = {
      preferred_styles: [
        ...designStyle.preferred_styles,
        ...designStyle.extra_styles,
      ],
      preferred_hues_finishes: [
        ...designStyle.preferred_hues_finishes,
        ...designStyle.extra_hues_finishes,
      ],
      preferred_materials: [
        ...designStyle.preferred_materials,
        ...designStyle.extra_materials,
      ],
      signature_style: designStyle.signature_style || '',
    };

    const cleanPersonaSettings = {
      id: personaSettings.id || null,
      handle: personaSettings.handle,
      tagline: personaSettings.tagline || '',
      description: personaSettings.description || '',
      instructions: personaSettings.instructions || [],
      approaches: personaSettings.approaches || [],
    };

    const payload = {
      personality: personaPersonality
        ? {
            engagement_style: personaPersonality.engagement_style,
            expression_style: personaPersonality.expression_style,
            tone_style: personaPersonality.tone_style,
            flow_style: personaPersonality.flow_style,
          }
        : undefined,
      design_style: cleanDesignStyle,
      persona: cleanPersonaSettings,
    };

    try {
      let response;
      if (personaSettings.id) {
        console.log('updating creator persona with payload:', payload);
        response = await updateCreatorPersona(payload, personaSettings.id);

        if (personaSettings.display_name) {
          setDisplayName(personaSettings.display_name);
          await updateCreator({
            id: creatorId,
            display_name: personaSettings.display_name
          });
        }

        // Update local state with the response data
        if (response.creator) {
          setPersonaPersonality(
            response.creator.personality || personaPersonality,
          );
          setPersonaSettings({
            ...response.creator.persona,
            display_name: personaSettings.display_name
          });
          setDesignStyle(response.creator.design_style || designStyle);
          setMyCreator({
            ...response.creator,
            display_name: personaSettings.display_name
          });
        }

        toast.success('Creator updated successfully!');
      } else {
        console.log('creating creator persona');
        response = await createCreatorPersona(payload);
        if (personaSettings.display_name) {
          await updateCreator({
            id: creatorId,
            display_name: personaSettings.display_name
          });
        }

        // Update local state with the response data
        if (response.creator) {
          setPersonaPersonality(response.creator.personality);
          setPersonaSettings({
            ...response.creator.persona,
            display_name: personaSettings.display_name
          });
          setDesignStyle(response.creator.design_style);
          setMyCreator({
            ...response.creator,
            display_name: personaSettings.display_name
          });
        }

        toast.success('Creator created successfully!');
      }
    } catch (e) {
      console.error('Error saving creator:', e);
      toast.error('Failed to save creator. Please try again.');
    }
  };

  const handleToggleVisibility = () => {
    console.log('toggle visibility');
  };

  const handleBack = () => {
    navigate('/my-creator');
  };

  const handleTabChange = async (tab) => {
    setSelectedTab(tab);
  };

  return (
    <Grid container flexGrow={1}>
      <Grid size={12}>
        <div className="tune-your-twin-container" ref={containerRef}>
          <CreatorTopNavigation
            handleBack={handleBack}
            handleDelete={handleDelete}
            handleSave={handleSave}
            handleToggleVisibility={handleToggleVisibility}
          />
          <div className="tune-your-twin-header">
            <div className="tune-your-twin-menu" ref={menuRef}>
              <div
                className={`tune-your-twin-button ${selectedTab === 'personality' ? 'selected' : ''}`}
                onClick={() => handleTabChange('personality')}
              >
                Personality
              </div>
              <div
                className={`tune-your-twin-button ${selectedTab === 'design' ? 'selected' : ''}`}
                onClick={() => handleTabChange('design')}
              >
                Design style
              </div>
              <div
                className={`tune-your-twin-button ${selectedTab === 'ai-twin' ? 'selected' : ''}`}
                onClick={() => handleTabChange('ai-twin')}
              >
                Your AI twin
              </div>
              <div
                className="hover-background"
                style={{
                  left: `${hoverPosition.left}px`,
                  width: `${hoverPosition.width}px`,
                }}
              />
            </div>
          </div>
          <div>
            {selectedTab === 'personality' && personaPersonality && (
              <TuneYourVoice
                personaPersonality={personaPersonality}
                setPersonaPersonality={setPersonaPersonality}
                handleNext={handleNext}
              />
            )}
            {selectedTab === 'design' && designStyle && (
              <TuneYourDesign
                designStyle={designStyle}
                setDesignStyle={setDesignStyle}
                handleNext={handleNext}
              />
            )}
            {selectedTab === 'ai-twin' && personaSettings && (
              <TuneYourAI
                personaSettings={personaSettings}
                setPersonaSettings={setPersonaSettings}
                creatorTexts={creatorTexts}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default TuneYourTwin;
