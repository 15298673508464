import React, { useEffect, useState } from 'react';
import TuneYourAIInstruction from '../tune-you-ai-instruction.component.jsx/tune-you-ai-instruction.component.jsx';
import Button from '../button/button.component.jsx';
import { getCreatorLink, updateCreator, getCreatorById } from '../../utils/api/creators';
import { useMyCreatorContext } from '../../context/my-creator.context.jsx';

import './tune-your-ai.styles.css';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MuiButton from '@mui/material/Button';
import { toast } from 'react-toastify';

const TuneYourAI = ({
  personaSettings,
  setPersonaSettings,
  creatorTexts,
  isLoading,
}) => {
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [imageError, setImageError] = useState(false);
  // Default values if creatorTexts is null
  const defaultTexts = {
    description: personaSettings?.description || '',
    instructions: personaSettings?.instructions || [],
    approaches: personaSettings?.approaches || [],
  };

  // Use default values if creatorTexts is null, and ensure arrays exist
  const texts = {
    description: creatorTexts?.description || defaultTexts.description,
    instructions: creatorTexts?.instructions || defaultTexts.instructions,
    approaches: creatorTexts?.approaches || defaultTexts.approaches,
  };
  const { myCreator, setMyCreator } = useMyCreatorContext();
  const [displayName, setDisplayName] = useState(myCreator?.display_name || '');

  useEffect(() => {
    setDisplayName(myCreator?.display_name || '');
  }, [myCreator?.display_name]);

  const handleDisplayNameChange = (e) => {
    const { value } = e.target;
    setDisplayName(value);
    setPersonaSettings({ ...personaSettings, display_name: value });
  };

  useEffect(() => {
    const fetchCreator = async () => {
      try {
        if (!personaSettings?.creator_id) return;      
        const creator = await getCreatorById(personaSettings.creator_id);
        console.log(creator);
        setProfileImageUrl(creator?.profile_image_url);
      } catch (error) {
        console.error('Error fetching creator:', error);
      }
    };

    fetchCreator();
  }, [personaSettings?.creator_id]);

  const handleSave = () => {
    console.log('Save');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonaSettings({ ...personaSettings, [name]: value });
  };

  const handleAddSupport = (field) => {
    setPersonaSettings({
      ...personaSettings,
      [field]: [...(personaSettings[field] || []), ''],
    });
  };

  const handleChangeSupport = (field, index, e) => {
    const { value } = e.target;
    const newSupport = [...(personaSettings[field] || [])];
    newSupport[index] = value;
    setPersonaSettings({ ...personaSettings, [field]: newSupport });
  };

  const handleDeleteSupport = (field, index) => {
    if (!personaSettings[field]) return;

    setPersonaSettings({
      ...personaSettings,
      [field]: personaSettings[field].filter((_, i) => i !== index),
    });
  };

  const handleCopyLink = () => {
    getCreatorLink(
      personaSettings?.handle,
      () => {
        toast.success('Link copied to clipboard!');
      },
      (err) => {
        console.error('Failed to copy link: ', err);
        toast.error('Failed to copy link');
      },
    );
  };

  const resizeImage = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        // Calculate new dimensions maintaining aspect ratio
        let width = img.width;
        let height = img.height;
        
        if (width < height) {
          // Portrait or square image
          width = 80;
          height = (img.height / img.width) * 80;
        } else {
          // Landscape image
          height = 80;
          width = (img.width / img.height) * 80;
        }
        
        canvas.width = width;
        canvas.height = height;
        
        // Draw resized image
        ctx.drawImage(img, 0, 0, width, height);
        
        // Convert to file
        canvas.toBlob((blob) => {
          const resizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });
          resolve(resizedFile);
        }, file.type, 0.8); // 0.8 quality for JPEG compression
        
        // Clean up
        URL.revokeObjectURL(img.src);
      };
    });
  };

  const onImageUpload = async (e) => {
    console.log('Image uploaded:', e.target.files[0]);
    const file = e.target.files[0];
    
    // Check that file is not over 5MB
    if (file.size > 5 * 1024 * 1024) {
      toast.error('File size must be less than 5MB');
      return;
    }
    
    // Check that file is an image
    if (!file.type.startsWith('image/')) {
      toast.error('File must be an image');
      return;
    }
    
    // Check that file is a supported image type
    const supportedImageTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/svg'];
    if (!supportedImageTypes.includes(file.type)) {
      toast.error('File must be a JPEG, PNG, WebP, or SVG');
      return;
    }

    try {
      // Resize the image
      const resizedFile = await resizeImage(file);
      
      // update creator profile with new avatar
      await updateCreator({
        id: personaSettings.creator_id,
        profile_image_url: resizedFile,
      });
      
      // Get updated creator profile
      const updatedCreator = await getCreatorById(personaSettings.creator_id);
      setProfileImageUrl(updatedCreator.profile_image_url);
      
    } catch (error) {
      console.error('Error processing image:', error);
      toast.error('Failed to process image');
    }
  };

  return (
    <div className="tune-your-ai-container">
      <input 
          type="file"
          id="imageUpload"
          accept=".jpg,.jpeg,.png,.webp,.svg"
          style={{ display: 'none' }}
          onChange={(e) => {
            onImageUpload(e);
          }}
        />
        <button
          className={`tune-your-ai-avatar-upload-button ${profileImageUrl && !imageError ? 'has-image' : ''}`}
          onClick={() => document.getElementById('imageUpload').click()}
        >
          {profileImageUrl && !imageError ? (
            <img 
              src={profileImageUrl} 
              alt="Creator Avatar" 
              className="tune-your-ai-avatar-image"
              onError={(e) => {
                console.log('Image failed to load:', profileImageUrl);
                setImageError(true);
                e.target.style.display = 'none';
              }}
              onLoad={() => {
                setImageError(false);
              }}
            />
          ) : (
            'Upload Creator Avatar'
          )}
        </button>
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>
        AI persona handle (no spaces)
      </Typography>
      {/* <p className="tune-your-ai-subtitle">Can't be changed later</p> */}
      <input
        type="text"
        name="handle"
        className="ai-text-input"
        value={personaSettings?.handle || ''}
        disabled={!!personaSettings?.id}
        placeholder="What do you want to be called?"
        onChange={handleChange}
      />
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>Display Name</Typography>
      <input
        type="text"
        name="display_name"
        className="ai-text-input"
        value={displayName}
        placeholder="What name would you like to display publicly?"
        onChange={handleDisplayNameChange}
      />
      <Typography variant="h6">Your AI persona link:</Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: 'center', margin: '12px 0' }}
      >
        Invite your audience to use your AI persona with this link
      </Typography>
      <Container
        sx={{ display: 'flex', justifyContent: 'center', marginBottom: '36px' }}
      >
        <MuiButton
          variant="contained"
          color="secondary"
          disabled={!personaSettings?.id}
          onClick={handleCopyLink}
        >
          Copy Your AI Persona Link
        </MuiButton>
      </Container>
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>
        AI twin tagline
      </Typography>
      <input
        type="text"
        name="tagline"
        className="ai-text-input"
        value={personaSettings?.tagline || ''}
        placeholder="What would you like to be known for?"
        onChange={handleChange}
      />
      {/* <h1 className="tune-your-ai-title">AI twin description</h1>
      {isLoading ? (
        <div className="ai-textarea-input-loading">
          Generating description...
        </div>
      ) : (
        <textarea
          name="description"
          className="ai-textarea-input"
          placeholder="Your AI twin description"
          value={texts.description}
          onChange={handleChange}
        />
      )}
      <div className="tune-your-ai-instructions-container">
        <h1 className="tune-your-ai-title">Communication instructions</h1>
        {isLoading ? (
          <div className="ai-textarea-input-loading">
            Generating instructions...
          </div>
        ) : (
          texts.instructions.map((instruction, index) => (
            <TuneYourAIInstruction
            key={index}
            value={instruction}
            onChange={(e) => handleChangeSupport('instructions', index, e)}
            handleDelete={() => handleDeleteSupport('instructions', index)}
            />
          ))
        )}
        <Button
          buttonType="instruction"
          onClick={() => handleAddSupport('instructions')}
        >
          Add instruction
        </Button>
      </div>
      <div className="tune-your-ai-design-approach-container">
        <h1 className="tune-your-ai-title">Design approach</h1>
        {isLoading ? (
          <div className="ai-textarea-input-loading">
            Generating approaches...
          </div>
        ) : (
          texts.approaches.map((approach, index) => (
            <TuneYourAIInstruction
              key={index}
            value={approach}
            onChange={(e) => handleChangeSupport('approaches', index, e)}
            handleDelete={() => handleDeleteSupport('approaches', index)}
            />
          ))
        )}
        <Button
          buttonType="instruction"
          onClick={() => handleAddSupport('approaches')}
        >
          Add approach
        </Button>
      </div> */}
      {/* <div className="tune-your-ai-save-container">
        <Button buttonType="login_form" onClick={handleSave}>
          Save
        </Button>
      </div> */}
    </div>
  );
};

export default TuneYourAI;
